<template>
    <div class="product-add" v-if="fetched">
        <div class="vx-row mb-base">
            <div class="vx-col lg:w-1/2 w-full">
                <vx-card title="Produkt Details" class="h-full">
                    <div class="vx-row">
                        <div class="vx-col lg:w-full w-full">
                            <div class="vx-row mt-0">
                                <div class="vx-col lg:w-full">
                                    <vs-checkbox v-model="data.active" class="ml-0">Produkt ist aktiv</vs-checkbox>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col w-full sm:w-1/2">
                                    <vs-input class="w-full" label="Order-Nr. *" data-vv-as="Order-Nr."
                                              name="ordernumber"
                                              v-model="data.ordernumber" type="text" v-validate="'required'"/>
                                    <span class="text-danger text-sm" v-if="errors.has('ordernumber')">{{ errors.first('ordernumber') }}</span>
                                </div>


                                <div class="vx-col w-full mt-4 sm:mt-0 sm:w-1/2">
                                    <vs-input class="w-full" label="Name *" data-vv-as="Name" name="name"
                                              v-model="data.name" type="text" v-validate="'required'"/>
                                    <span class="text-danger text-sm" v-if="errors.has('name')">{{ errors.first('name') }}</span>
                                </div>

                            </div>
                            <div class="vx-col lg:w-full w-full mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-select label="Art des Produktes" v-model="data.article_type_id" class="w-full">
                                        <vs-select-item :key="item.id" :value="item.id" :text="item.name"
                                                        v-for="(item, index) in articleTypes"/>
                                    </vs-select>
                                </div>
                            </div>

                            <div class="vx-row mt-6">
                                <div class="vx-col lg:w-full">
                                    <vs-checkbox v-model="data.is_hour_unit" class="ml-0" @change="onChangeHourUnit">Produkt wird über den Stundensatz abgerechnet</vs-checkbox>
                                </div>
                            </div>

                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-checkbox v-model="data.discountable" class="ml-0" @change="onChangeHourUnit">Produkt ist rabattierbar.</vs-checkbox>
                                </div>
                            </div>

                            <div class="vx-col lg:w-full w-full mt-3">
                                <div class="vx-row">
                                    <div class="vx-col lg:w-full">
                                        <label class="vs-input--label" v-if="!data.is_hour_unit">Einzelpreis</label>
                                        <label class="vs-input--label" v-if="data.is_hour_unit">Stundensatz</label>
                                        <currency-input class="vs-input--input normal" v-model="data.price"></currency-input>
                                    </div>
                                </div>
                                <div class="vx-row mt-4">
                                    <div class="vx-col lg:w-full">
                                        <label class="vs-input--label">Mindestpreis</label>
                                        <currency-input class="vs-input--input normal" v-model="data.min_price"></currency-input>
                                    </div>
                                </div>
                                <div class="vx-row mt-4">
                                    <div class="vx-col lg:w-full">
                                        <label class="vs-input--label">Einkaufspreis</label>
                                        <currency-input class="vs-input--input normal" v-model="data.purchase_price"></currency-input>
                                    </div>
                                </div>
                                <div class="vx-row mt-4">
                                    <div class="vx-col w-full sm:w-1/2">
                                        <vs-select label="MwSt." v-model="data.tax" class="w-full">
                                            <vs-select-item :key="index" :value="item.value" :text="item.label"
                                                            v-for="(item, index) in taxOptions"/>
                                        </vs-select>
                                    </div>
                                    <div class="vx-col w-full sm:w-1/2">
                                        <vs-select label="Einheit" v-model="data.unit" class="w-full">
                                            <vs-select-item :key="index" :value="item.value" :text="item.label"
                                                            v-for="(item, index) in unitOptions"/>
                                        </vs-select>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </vx-card>
            </div>
            <div class="vx-col lg:w-1/2 w-full">
                <vx-card title="Produkt Beschreibung" class="h-full">
                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <label class="vs-input--label">Beschreibung</label>
                            <vue-editor name="description" v-model="data.description" :editorToolbar="toolbar" data-vv-as="Beschreibung" />
                            <span class="text-danger text-sm" v-if="errors.has('description')">{{ errors.first('description') }}</span>
                        </div>

                        <div class="vx-col w-full mt-4">
                            <label class="vs-input--label">Interne Beschreibung</label>
                            <vue-editor v-model="data.internal_description" :editorToolbar="toolbar" />
                        </div>

                        <div class="vx-col w-full mt-4">
                            <label class="vs-input--label">Interne Workflow-Beschreibung</label>
                            <vue-editor v-model="data.internal_workflow_description" :editorToolbar="toolbar" />
                        </div>
                        <div class="vx-col w-full mt-4">
                            <div class="vx-row">
                                <div class="vx-col w-1/2">
                                    <category-selector v-model="data.categories" type="product"></category-selector>
                                </div>
                                <div class="vx-col w-1/2">
                                    <tag-selector v-model="data.tags" type="product"></tag-selector>
                                </div>
                            </div>
                        </div>
                    </div>
                </vx-card>
            </div>
        </div>

        <div class="flex mt-8 justify-end">
            <vs-button color="warning" type="border" class="mb-4" :to="{name :'products-and-services'}">Abbrechen</vs-button>
            <vs-button class="ml-3 mb-4" @click="saveArticle">Hinzufügen</vs-button>
        </div>
    </div>
</template>

<script>
    import ApiService from "../../../api";
    import staticOptions from "../../../mixins/static/options";
    import CategorySelector from "../../../components/search/CategorySelector";
    import TagSelector from "../../../components/search/TagSelector";

    import { Quill } from "vue2-editor";

    const Block = Quill.import('blots/block');
    Block.tagName = 'DIV';
    Quill.register(Block, true);

    export default {
        components: {TagSelector, CategorySelector},
        data() {
            return {
                fetched: false,
                data: {
                    active: 1,
                    ordernumber: null,
                    unit: 1,
                    name: null,
                    description: null,
                    internal_description: null,
                    internal_workflow_description: null,
                    price: 0,
                    min_price: 0,
                    purchase_price: 0,
                    tax: 19,
                    article_type_id: 1,
                    discountable: 0,
                    categories: null,
                    tags: null
                },
                toolbar: [
                    ['bold', 'italic', 'underline'],
                    [{'list': 'ordered'}, {'list': 'bullet'}],
                    [{'color': []}],
                ],
                articleTypes: [],
                taxOptions: staticOptions.tax,
            }
        },
        mounted() {
            this.fetchInitialData();
        },
        computed: {
            unitOptions() {
                if(!this.data.is_hour_unit) {
                    return staticOptions.units.filter(item => !item.hour_unit);
                }

                return staticOptions.units.filter(item => item.hour_unit);
            }
        },
        methods: {
            onChangeHourUnit() {
                if(!this.data.is_hour_unit) {
                    return this.data.unit = staticOptions.units.find(item => !item.hour_unit).value;
                }

                return    this.data.unit = staticOptions.units.find(item => item.hour_unit).value;
            },
            fetchInitialData() {
                ApiService.get('articles?limit=1&order[field]=ordernumber&order[direction]=desc').then((response) => {
                    if(response.data.result) {
                        let number = parseInt(response.data.result[0].ordernumber);
                        this.data.ordernumber = number + 1;
                    }
                    this.fetched = true;
                });

                ApiService.get('article_types')
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.articleTypes = response.data.result;
                        }
                    });
            },
            saveArticle() {
                ApiService.post('articles', this.data).then((response) => {
                    if(response.data.result) {
                        return this.$router.push({ name: 'products-and-services-detail', params: { id: response.data.result.id }})
                    }
                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Das Produkt konnte nicht erstellt werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                }).catch((error) => {
                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Das Produkt konnte nicht erstellt werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                });
            }
        },
    }
</script>

<style lang="scss">
    .product-add {
        .quillWrapper {
            .ql-editor {
                //height: 7rem;
                min-height: 6rem;
            }
        }
    }
</style>
